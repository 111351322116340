import { URI } from "../payment-token.api";
import { _get, _post } from "@api/doinsport/services/httpService";

const URI_OPERATION = URI + "/operations"

export const postOperation = (operation) => _post(URI_OPERATION, operation);

export const getCustomerPaymentTokensHistory = (clientId, page, totalRows) => _get(
    URI_OPERATION +
    '?client.id=' + clientId +
    '&page='+ page +
    '&itemsPerPage=' + totalRows
)

