<template>
  <d-side-bar
    :model="showDetails"
    :right-position="position === 'right'"
    :left="position === 'left' ? sideModalWidth : 'auto'"
    :right="position === 'right' ? sideModalWidth : 'auto'"
    top="0"
    width="500px"
    bg-dark="#0b162047"
    identifier="sideModal"
    header-class="header-modal"
    @hidden="beforeHide"
    @shown="$emit('shown')"
    @change="$emit('change')"
  >
    <div slot="header">
      <div class="profile-informations">
        <b-row class="mt-0 mb-0">
          <b-col
            class="d-flex align-items-center align-content-center"
            cols="1"
          >
            <img
              v-if="participant.partcipantPreview.photo !== null"
              :src="$filePath(participant.partcipantPreview.photo)"
              height="60"
              width="60"
              class="customer-img"
            >
            <template v-else>
              <d-avatar
                :full-name="participant.about"
                size="60"
                color="#DDDDDD"
                text-color="#4D4F5C"
                text-font-size="30"
              />
            </template>
          </b-col>
          <b-col
            class="d-flex align-items-center align-content-center"
            cols="8"
          >
            <b-row class="ml-4">
              <b-col class="customer-name" cols="12">
                {{ participant.about }}
              </b-col>
              <b-col v-if="participantEmail" class="customer-email" cols="12">
                {{ participantEmail }}
              </b-col>
              <b-col class="customer-email" cols="12">
                {{ participant.phoneNumber }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
    <div slot="body">
      <b-row class="container-fluid pr-0">
        <b-col
          align="left"
          cols="6"
        >
          <label class="resume-label mt-3">
            {{ $t('views.planning.payment.resume') }}
          </label>
        </b-col>
        <b-col
          v-if="participantDetails"
          class="d-flex align-items-center justify-content-end mt-1"
          align="right"
          cols="6"
        >
          <d-button
            v-if="participantDetails.restToPay <= 0"
            :text="$t('components.custom.planning.booking-details-modal.general-information.table.paid')"
            :class="'d-btn-success'"
            :translated-text="true"
            class="d-btn-sm font-text-title btn font-text-title custom-btn-width"
          />
          <d-button
            v-else
            :text="$t('components.custom.planning.booking-details-modal.general-information.table.not-paid')"
            :class="'d-btn-danger'"
            :translated-text="true"
            class="d-btn-sm font-text-title btn font-text-title custom-btn-width"
          />
        </b-col>
      </b-row>
      <d-table
        identifier="resume-payment"
        :tablefields="fields"
        :items="tableItems"
        :per-page="1"
        :totalRows="1"
        :is-busy="isBusy"
        :show-pagination="false"
        :tr-cursor-pointer="false"
        class="dataTable"
      ></d-table>

      <b-row
        v-if="participantDetails"
        class="mt-2"
      >
        <b-col align="right" cols="12" class="sales-payment">
          <div
            class="d-flex justify-content-end"
            :class="innerWidth ? ' pr-5' : 'pr-3'"
          >
            <label class="price-label mt-2 mr-2">
              {{ $t('total-amount') }}
              {{ (participantDetails.price / 100).toFixed(2) }}
              {{ $currency }}
            </label>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col align="right" cols="12" class="white-container">
          <div
            class="d-flex justify-content-end"
            :class="innerWidth ? ' pr-5' : 'pr-3'"
          >
            <label class="price-label mt-2 mr-2">
              {{ $t('paid-amount') }}
              {{ paidAmount }}
              {{ $currency }}
            </label>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="participantDetails">
        <b-col align="right" cols="12" class="sales-payment">
          <div
            class="d-flex justify-content-end"
            :class="innerWidth ? ' pr-5' : 'pr-3'"
          >
            <label class="price-label mt-2 mr-2">
              {{ $t('due-amount') }}
              {{ dueAmount }}
              {{ $currency }}
            </label>
          </div>
        </b-col>
      </b-row>
      <payment-history
        :participant="participant"
        :booking-details="bookingDetails"
        @on:add-regulation="$emit('on:add-regulation')"
      />
    </div>
  </d-side-bar>
</template>
<script>
import {_get} from "@api/doinsport/services/httpService";
import PaymentHistory from "@custom/planning/PaymentHistory";

export default {
  components: {PaymentHistory},
  data() {
    return {
      display: false,
      isBusy: false,
      customer: null,
      bookingDetails: null,
      participantDetails: null,
      items: [],
    }
  },
  props: {
    position: {
      type: String,
      default: 'left'
    },
    showDetails: {
      type: Boolean,
      default: false
    },
    refreshPayments: {
      type: Boolean,
      default: false
    },
    participant: {
      type: Object,
      default: () => {
      },
    },
    booking: {
      type: Object,
      default: () => {
      },
    },
  },
  watch: {
    participant: function () {
      this.loadCustomer();
    },
    refreshPayments: function () {
      this.loadParticipant();
    }
  },
  computed: {
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 500;
    },
    sideModalWidth() {
      if (this.$route.name === 'planning') {
        return this.$store.getters['layout/getInnerWidth'] > 900 ? '840px' : '0'
      } else {
        return this.$store.getters['layout/getInnerWidth'] > 900 ? '880px' : '0'
      }
    },
    paidAmount() {
      if (this.participantDetails) {
        return (((this.participantDetails.price) - parseInt(this.participantDetails.restToPay)) / 100).toFixed(2);
      }
    },
    dueAmount() {
      if (this.participantDetails) {
        return (this.participantDetails.restToPay / 100).toFixed(2);
      }
    },
    participantEmail() {
      if (this.customer) {
        return this.customer.email;
      }
      return null;
    },
    tableItems() {
      return [
        {
          quantity: 1,
          total: this.participant.colToPay,
          item: this.booking.timetableBlockPrice.name,
        }
      ]
    },
    fields() {
      return [
        {
          class: 'text-left',
          key: "quantity",
          label: this.$t('views.planning.payment.quantity'),
        },
        {
          key: "item",
          label: this.$t('views.planning.payment.item'),
        },
        {
          key: "total",
          label: this.$t('views.planning.payment.total'),
        },
      ];
    },
  },
  methods: {
    beforeHide() {
      this.$emit('hidden');
    },
    loadParticipant() {
      _get(this.participant.iriParticipant)
        .then((response) => {
          this.participantDetails = response.data;
          this.loadBooking();
        })
      ;
    },
    loadCustomer() {
      _get(this.participant.participant.iri)
        .then((response) => {
          this.customer = response.data;
          this.loadParticipant();
        })
      ;
    },
    loadBooking() {
      _get(this.booking['@id'])
        .then((response) => {
          this.bookingDetails = response.data;
          this.bookingDetails.payments.sort((b, a) => (a.payment.createdAt > b.payment.createdAt) ? 1 : ((b.payment.createdAt > a.payment.createdAt) ? -1 : 0))
        })
      ;
    }
  },
  created() {
    this.loadCustomer();
  },
}

</script>

<style lang="scss" scoped>
@import "@lazy/planning/_sidebar-modal-payment-details.scss";
</style>
