<template>
  <b-row class="mt-4">
    <b-col class="mt-1" cols="6">
      {{ $t('components.custom.planning.booking-details-modal.participant-payment-modal.credit') }}
    </b-col>
    <b-col cols="6">
      <select
        v-model="selectedPaymentToken.value"
        size="sm"
        class="form-control background-light-blue-inputs"
      >
        <template v-for="(token, i) in customer.paymentTokens">
          <option
            v-if="isValidBlockPricePaymentToken(token)"
            :key="'token-option' + i"
            :value="token.clubPaymentTokenId"
          >
            {{ token.name }}
          </option>
        </template>
      </select>
    </b-col>
    <b-col class="mt-4" cols="6">
      {{ $t('components.custom.planning.booking-details-modal.participant-payment-modal.sold-to-pay') }}
    </b-col>
    <b-col class="mt-3" cols="6">
      <div class="d-flex flex-row align-content-center align-items-center">
        <b-form-input
          v-model="toPay.value"
          :placeholder="$t('general.actions.numbers.zero')"
          size="sm"
          min="0.00"
          step="0.5"
          type="number"
          autocomplete="off"
          class="background-light-blue-inputs custom-right-borders"
        >
        </b-form-input>
        <div class="coins-container">
          <img class="form-control-coin-sm" :src="coinsIcons">
        </div>
      </div>
    </b-col>
    <b-col cols="12">
      <balance-credit
        :payment-tokens="paymentTokens"
        :selected-payment-token-id="selectedPaymentToken.value"
        view="paymentModal"
      />
    </b-col>
  </b-row>
</template>
<script>

import {getAllClientPaymentToken} from "@api/doinsport/services/client/payment-token/payment-token.api";
import BalanceCredit from "@views/client/details/body/right/balance/BalanceCredit";

export default {
  components: {BalanceCredit},
  data: () => ({
    coinsIcons: require('@/assets/coins.svg'),
    paymentTokens: []
  }),
  props: {
    customer: {
      type: Object,
      default: () => {
      },
    },
    toPay: {
      type: Object,
      default: () => {
      },
    },
    participant: {
      type: Object,
      default: () => {
      },
    },
    selectedPaymentToken: {
      type: Object,
      default: () => {
      },
    },
    paymentTokenPrices: {
      type: Array,
      default: () => {
      },
    },
    blockPriceVariations: {
      type: Array,
      default: () => {
      },
    },
  },
  methods: {
    loadClientPaymentTokens(clientId, balance = 0) {
      this.paymentTokens = [];
      const filters = '&order[createdAt]=asc&available=true';

      getAllClientPaymentToken(clientId, balance)
        .then((response) => {
          this.paymentTokens =  response.data['hydra:member'];
        })
      ;
    },
    isValidBlockPricePaymentToken(token) {
      let notFound = false;

      for (const participantToken of this.paymentTokenPrices) {
        if (participantToken.paymentToken === '/clubs/payment-tokens/' + token.clubPaymentTokenId) {
          if (participantToken.pricePerParticipant > 0) {
            notFound = true;
            break;
          }
        }
      }

      return notFound;
    }
  },
  created() {
    this.loadClientPaymentTokens(this.customer.id, 0);
  }
}
</script>
<style lang="scss" scoped>

.coins-container {
  border: 1px solid #ced4da;
  border-left: unset;
  background-color: #EBEBEB;
  border-bottom-right-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: unset;
  font-size: 0.875rem;
}

.form-control-coin-sm {
  height: calc(1.494em + 0.5rem);
  padding: 0.24rem 0.5rem;
  font-size: 0.87rem;
  line-height: 0;
  border-radius: 0.2rem;
}

/deep/ .input-group-text {
  line-height: 0;
  color: #0B2772;
}

.custom-left-borders {
  border-bottom-left-radius: unset;
  border-top-left-radius: unset;
}

.custom-right-borders {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
</style>
