<template>
  <div>
    <b-row :class="view === 'customer' ? '' : 'mt-4'">
      <b-col align="left">
        <label
          :class="view === 'customer' ? 'balance-title' : 'popup-title'"
        >
          {{ $t('views.client.details.body.right.balance.balance-credit') }}
        </label>
      </b-col>
      <b-col
        align="right"
      >
        <label
          v-if="view === 'customer'"
          :class="creditHistoryDetails.length === 0 ? 'history-label-disabled': 'history-label pointer'"
          @click="loadCreditsHistory"
        >
          {{ $t('views.client.details.body.right.balance.history') }}
        </label>
      </b-col>
    </b-row>
    <div
      class="row border-bottom-grey"
    >
    </div>
    <div
      class="scroll-section"
    >
      <div class="pr-2">
        <template
          v-if="!edit"
          v-for="(paymentToken, key) of paymentTokens"
        >
          <div
            v-if="isValidPaymentToken(paymentToken)"
            :key="'payment-token' + key"
            class="pt-3"
          >
            <b-row
              no-gutters
            >
              <b-col>
                <b-row no-gutters> {{ paymentToken.name }}</b-row>
                <b-row
                  v-if="view === 'paymentModal'"
                  :class="view === 'paymentModal' ? 'd-bg-dark' : 'd-bg-gray'"
                  no-gutters
                >
                  {{ $t('views.client.details.body.right.balance.created-at') }}
                  {{ $moment(paymentToken.createdAt).format('DD/MM/YYYY') }}
                </b-row>
                <div v-if="paymentToken.expiresAt">
                  <b-row
                    v-if="notExpired(paymentToken)"
                    :class="view === 'paymentModal' ? 'd-bg-dark' : 'd-bg-gray'"
                    no-gutters
                  >
                    {{ $t('views.client.details.body.right.balance.expires-at') }}
                    {{ getExpiresAtDate(paymentToken.expiresAt) }}
                  </b-row>
                  <template
                    v-else
                  >
                    <b-row
                      v-if="view === 'customer'"
                      no-gutters
                      class="bg-light-red"
                    >
                      {{ $t('views.client.details.body.right.balance.expired') }}
                    </b-row>
                  </template>
                </div>
                <b-row
                  v-else
                  :class="view === 'paymentModal' ? 'd-bg-dark' : 'd-bg-gray'"
                  no-gutters
                >
                  {{ $t('views.client.details.body.right.balance.expires-at') }}
                  {{ $t('views.client.details.body.right.balance.permanent') }}
                </b-row>
              </b-col>
              <b-col
                align="middle"
                cols="3"
              >
                <span
                  :class="view === 'paymentModal' ? 'd-bg-dark' : 'd-bg-gray'"
                >
                  {{ paymentToken.balance }}
                </span>
              </b-col>
              <b-col
                v-if="view === 'customer'"
                align="right"
                cols="3"
              >
                <span
                  v-if="!edit"
                  class="d-bg-gray"
                  @click="editPaymentToken(paymentToken)"
                >
                  <i class="pointer icofont icofont-ui-edit"></i>
                </span>
              </b-col>
            </b-row>
          </div>
          <div
            v-if="key < paymentTokens.length - 1 && isValidPaymentToken(paymentToken)"
            class="pt-3 border-bottom-grey"
          />
        </template>
      </div>
    </div>
    <payment-token-form
      v-if="edit"
      :payment-token="selectedPaymentToken"
      @on:leave="onLeave"
    />
    <payment-token-list-modal
      :display="display"
    />
  </div>
</template>
<script>
import PaymentTokenListModal from "@custom/clients/history/PaymentTokenListModal";
import {getCustomerPaymentTokensHistory} from "@api/doinsport/services/client/payment-token/operation/operation.api";

export default {
  data: () => ({
    edit: false,
    display: false,
    selectedPaymentToken: null,
    creditHistoryDetails: [],
  }),
  components: {
    PaymentTokenListModal,
    PaymentTokenForm: () => import('@form/client/payment-token/PaymentTokenForm'),
  },
  props: {
    paymentTokens: {
      type: Array,
      default: () => {
      },
    },
    selectedPaymentTokenId: {
      type: String,
      default: null,
    },
    view: {
      type: String,
      default: 'customer',
    },
  },
  methods: {
    isValidPaymentToken(token) {
      if (this.view === 'paymentModal' && this.selectedPaymentTokenId) {
        return token.paymentToken.id === this.selectedPaymentTokenId && token.balance > 0;
      }
      return true;
    },
    notExpired(paymentToken) {
      if (paymentToken.expiresAt) {
        return this.$moment(paymentToken.expiresAt).isAfter(this.$moment());
      }
      return false;
    },
    editPaymentToken(paymentToken) {
      this.selectedPaymentToken = paymentToken;
      this.edit = true;
      this.$emit('on:interface-switch', this.edit);
    },
    onLeave() {
      this.edit = false;
      this.$emit('reload:payment-tokens');
      this.$emit('on:interface-switch', this.edit);
    },
    getExpiresAtDate(expireAt) {
      return this.$moment(expireAt).format('DD/MM/YYYY');
    },
    loadCreditsHistory() {
      if (this.creditHistoryDetails.length > 0) {
        this.display = !this.display;
      }
    },
    loadCustomerCreditHistory() {
      getCustomerPaymentTokensHistory(this.$route.params.id, 1, 500)
        .then((response) => {
          this.creditHistoryDetails = response.data['hydra:member']
        })
      ;
    }
  },
  created() {
    if (this.view === 'customer') {
      this.loadCustomerCreditHistory();
    }
  }
}
</script>
<style scoped>
.balance-title {
  text-align: left;
  font: normal normal 900 18px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

@media only screen and (max-width: 1300px) and (min-width: 890px) {
  .balance-title {
    font-size: 15px;
  }
}

.history-label {
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #0B2772;
  opacity: 1;
  cursor: pointer;
}

.history-label-disabled {
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #b5aeae;
  opacity: 1;
}

.d-bg-gray:hover {
  color: #707070;
}

.d-bg-dark {
  font-size: 13px;
  color: #1B1B29;
}

.container-credit-scroll {
  position: relative;
  max-height: 240px;
  overflow-x: hidden;
}

.popup-title {
  text-align: left;
  font: normal normal bold 13px Avenir;
  color: #1B1B29;
  opacity: 1;
  font-size: 25px;
}

/deep/ ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

/deep/ ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.scroll-section {
  min-width: auto;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  max-height: 208px;
}
</style>
