<template>
  <b-modal
    :id="modalId"
    hide-header
    hide-footer
    hide-header-close
    size="xl"
    body-bg-variant="gray-lighten"
  >
    <b-row align="center">
      <b-col class="credit-title-label">
        {{ $t('components.custom.clients.history.payment-token-list-modal.title') }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3">
    </div>
    <b-card
      class="history-card"
    >
      <d-table
        :key="componentKey + 'table'"
        :tablefields="tableFields"
        :per-page="itemsPerPage"
        :totalRows="totalRows"
        :items="items"
        :is-busy="isBusy"
        :show-pagination="true"
        @current-page:update="onCurrentPageUpdate"
      >
      </d-table>
    </b-card>
    <b-row>
      <b-col align="right">
        <d-button
          :text="'general.actions.dismiss'"
          :class="'d-btn btn d-btn-danger font-text-title'"
          @on:button-click="$bvModal.hide(modalId)"
        />
      </b-col>
      <b-col align="left">
        <csv-downloader
          :data="csvData"
          :name="csvName"
        >
          <label id="linker" hidden>
            {{ $t('components.custom.clients.history.payment-token-list-modal.title') }}
          </label>
          <d-button
            :text="'general.actions.export'"
            :class="'d-btn btn d-btn-default font-text-title'"
            icon="fa fa-external-link mr-1"
            @on:button-click="onExport"
          />
        </csv-downloader>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>

import {getCustomerPaymentTokensHistory} from "@api/doinsport/services/client/payment-token/operation/operation.api";

export default {
  data: () => ({
    componentKey: 1,
    itemsPerPage: 10,
    currentPage: 1,
    totalRows: 1,
    isBusy: true,
    items: [],
    csvData: [],
  }),
  components: {
    CsvDownloader: () => import ('vue-json-csv'),
  },
  props: {
    modalId: {
      type: String,
      default: 'modal-history-credit'
    },
    watched: {
      type: Boolean,
      default: false
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    display: function () {
      this.loadCreditHistory(this.currentPage);
      this.$bvModal.show(this.modalId);
    }
  },
  computed: {
    csvName() {
      return this.$t('components.custom.clients.history.payment-token-list-modal.title') + ' ' + this.$moment.utc().format('DD/MM/YYYY') + '.csv';
    },
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    tableFields() {
      return [
        {
          key: "historyDate",
          label: this.$t('components.custom.clients.history.payment-token-list-modal.table.date'),
          sortable: true
        },
        {
          key: "historyCredit",
          label: this.$t('components.custom.clients.history.payment-token-list-modal.table.credit'),
          sortable: true
        },
        {
          key: "historyPaymentTokenCreditValue",
          class: this.alignCellsToLeft,
          label: this.$t('components.custom.clients.history.payment-token-list-modal.table.value'),
          sortable: true
        },
        {key: "action", label: this.$t('components.custom.clients.history.payment-token-list-modal.table.action')},
      ];
    }
  },
  methods: {
    onExport() {
      document.getElementById('linker').click();
    },
    onCurrentPageUpdate(page) {
      this.currentPage = page;
      this.loadCreditHistory(page);
    },
    loadCreditHistory(currentPage) {
      this.items = [];
      this.csvData = [];
      this.isBusy = true;

      getCustomerPaymentTokensHistory(this.$route.params.id, currentPage, this.itemsPerPage).then((response) => {
        this.totalRows = response.data['hydra:totalItems'];

        for (const item of response.data['hydra:member']) {
          let historyItem = {
            historyDate: this.$toTimezone(item.createdAt).format('DD/MM/YYYY HH:mm'),
            action: item.label,
            historyPaymentTokenCreditValue: {credit: item.credit, debit: item.debit},
            historyCredit: item.paymentToken.name,
          };

          let csvItem = {};
          csvItem[this.$t('components.custom.clients.history.payment-token-list-modal.table.date')] = this.$toTimezone(item.createdAt).format('DD/MM/YYYY HH:mm');
          csvItem[this.$t('components.custom.clients.history.payment-token-list-modal.table.credit')] = item.paymentToken.name;
          csvItem[this.$t('components.custom.clients.history.payment-token-list-modal.table.value')] = parseInt(item.credit) === 0 ? '-' + item.debit : item.credit;
          csvItem[this.$t('components.custom.clients.history.payment-token-list-modal.table.action')] = item.label;

          this.items.push(historyItem);
          this.csvData.push(csvItem);
        }
      })
        .finally(() => {
          this.isBusy = false;
        })
    }
  },
  created() {
    if (this.watched) {
      this.loadCreditHistory(this.currentPage);
    }
  }
}
</script>
<style scoped>

.credit-title-label {
  font: normal normal 900 18px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

/deep/ .modal {
  padding-top: 40px
}

/deep/ .modal-content {
  border-radius: 8px;
}

/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  border-radius: 8px;
}

/deep/ .card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #C4C8D066;
  border-radius: 8px;
  opacity: 1;
}

/deep/ .card .card-header {
  background-color: #fff;
  padding-top: 8px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 10px;
  border-bottom: unset;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

/deep/ .card .card-body {
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  background-color: transparent;
}
</style>
