<template>
  <b-row class="mt-4">
    <b-col
      class="mt-1"
      cols="6"
    >
      {{ $t('components.custom.planning.booking-details-modal.participant-payment-modal.wallet-sold') }}
    </b-col>
    <b-col align="right" cols="6">
      <label
        :class="customer.wallet.balance > 0 ? 'wallet-sold-success' : 'wallet-sold-danger'"
      >
        {{ customer ? customer.wallet.balance / 100 : '0' }} {{ $currency }}
      </label>
    </b-col>
    <b-col
      class="mt-4"
      cols="6"
    >
      {{ $t('components.custom.planning.booking-details-modal.participant-payment-modal.sold-to-pay') }}
    </b-col>
    <b-col class="mt-3" cols="6">
      <div class="d-flex flex-row align-content-center align-items-center">
        <b-input-group>
          <b-form-input
            v-model="toPay.value"
            :disabled="isDisabledWalletField"
            :placeholder="$t('general.actions.numbers.zero')"
            size="sm"
            min="0.00"
            step="0.5"
            type="number"
            class="background-light-blue-inputs"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <div>{{ $currency }}</div>
          </b-input-group-append>
        </b-input-group>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  data: () => ({
    coinsIcons: require('@/assets/coins.svg')
  }),
  props: {
    customer: {
      type: Object,
      default: () => {}
    },
    toPay: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isDisabledWalletField () {
      if (null !== this.customer.wallet) {
        return this.customer.wallet.balance <= 0
      }

      return true;
    },
  }
}
</script>
<style lang="scss" scoped>

.coins-container {
  border: 1px solid #ced4da;
  border-left: unset;
  background-color: #EBEBEB;
  border-bottom-right-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: unset;
  font-size: 0.875rem;
}

.form-control-coin-sm {
  height: calc(1.494em + 0.5rem);
  padding: 0.24rem 0.5rem;
  font-size: 0.87rem;
  line-height: 0;
  border-radius: 0.2rem;
}

/deep/ .input-group-text {
  line-height: 0;
  color: #092772;
}

.custom-left-borders {
  border-bottom-left-radius: unset;
  border-top-left-radius: unset;
}

.custom-right-borders {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.wallet-sold-success {
  text-align: left;
  font: normal normal medium 18px/24px Avenir;
  letter-spacing: 0.56px;
  color: #309648;
  opacity: 1;
}
.wallet-sold-warning {
  text-align: left;
  font: normal normal medium 18px/24px Avenir;
  letter-spacing: 0.56px;
  color: #e0a338;
  opacity: 1;
}
.wallet-sold-danger {
  text-align: left;
  font: normal normal medium 18px/24px Avenir;
  letter-spacing: 0.56px;
  color: #f30a0a;
  opacity: 1;
}

</style>
