<template>
  <div>
    <d-table
      :key="tableKey"
      :is-busy="isBusy"
      :totalRows="totalRows"
      :per-page="itemsPerPage"
      :current-page="currentPage"
      :show-pagination="false"
      :overflow-enabled="false"
      :tr-cursor-pointer="false"
      :items="isFormulaType ? participantsFormulas : items"
      :tablefields="isFormulaType ? formulasFields : defaultFields"
      identifier="participants"
      delete-modal-title="components.custom.planning.booking-details-modal.general-information.delete-modal.title"
      delete-modal-description="components.custom.planning.booking-details-modal.general-information.delete-modal.description"
      @row-clicked="onRowClicked"
      @on:participants:delete="removeParticipant"
      @on:data-table-btn:pay="payParticipant"
      @on:data-table-btn:details="showDetailsParticipant"
    >
    </d-table>
    <participant-payment-modal
      :display="display"
      :hide="hide"
      :booking="booking"
      :participant="selectedParticipant"
      :timetable-block-price="timetableBlockPrice"
      @on:payment-succeed="refreshBookingParticipants"
    />
    <participant-payment-details-modal
      v-if="selectedParticipant"
      :hide="hide"
      :booking="booking"
      :position="position"
      :show-details="showDetails"
      :participant="selectedParticipant"
      :refresh-payments="refreshPayments"
      class="custom-sub-modal"
      @hidden="showDetails = false;"
      @on:add-regulation="display = !display"
    />
  </div>
</template>
<script>
import {insertIntoArray, isNotUndefinedAndNotNull} from "@/utils/classes";
import {capitalize} from "@/utils/string";
import {getBooking} from "@api/doinsport/services/bookings/booking.api";
import {_put} from "@api/doinsport/services/httpService";
import ParticipantPaymentModal from "@custom/planning/ParticipantPaymentModal";
import ParticipantPaymentDetailsModal from "@custom/planning/ParticipantPaymentDetailsModal";
import {hydrate} from "@/utils/form";
import {FORMULA_BOOKING_TYPE, LESSON_BOOKING_TYPE} from "@/classes/doinsport/BookingModel";
import {LEISURE_TYPE, LESSON_TYPE} from "@/classes/doinsport/Price";

export default {
  components: {ParticipantPaymentModal, ParticipantPaymentDetailsModal},
  props: {
    items: {
      type: Array,
      default: () => []
    },
    canceledParticipants: {
      type: Array,
      default: () => []
    },
    position: {
      type: String,
      default: 'left'
    },
    alreadyCanceled: {
      type: Array,
      default: () => []
    },
    booking: {
      type: Object,
      default: this
    },
    timetableBlockPrice: {
      type: Object,
      default: this
    },
    refreshParticipants: {
      type: Boolean,
      default: false
    },
    isBusy: {
      type: Boolean,
      default: false
    },
    showRegulation: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    itemsPerPage: 500,
    display: false,
    refreshPayments: false,
    showDetails: false,
    tableKey: 1,
    selectedParticipant: null,
    dataResponse: null,
    totalRows: 1,
    promiseResponses: [],
    formulasParticipants: [],
    currentPage: 1,
  }),
  watch: {
    refreshParticipants: function () {
      this.loadBookingParticipants();
    },
    showRegulation: function () {
      for (const item of this.items) {
        if (item.client) {
          this.payParticipant(item);
          break;
        }
      }
    },
  },
  computed: {
    isFormulaType() {
      return this.booking.activityType === FORMULA_BOOKING_TYPE;
    },
    participantsFormulas() {
      this.formulasParticipants = [];

      if (this.isFormulaType) {

        for (const item of this.items) {
          if (item.client || item.user) {
            this.formulasParticipants.push(item);
          }
        }
      }

      return this.formulasParticipants;
    },
    blockPrices() {
      return this.$store.getters["playgrounds/getSelectedBlockPrices"];
    },
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    alignCellsToCenter() {
      return 'text-center';
    },
    formulasFields() {
      return [
        {
          key: "partcipantPreview",
          class: this.alignCellsToLeft,
          label: '',
          sortable: false
        },
        {
          key: "about",
          label: this.$t('components.custom.planning.booking-details-modal.general-information.table.about'),
          sortable: false,
        },
        {
          key: "phoneNumber",
          class: this.alignCellsToCenter,
          label: this.$t("views.client.index.phoneNumber")
        },
      ];
    },
    defaultFields() {
      let bookingFields = [
        {
          key: "partcipantPreview",
          class: this.alignCellsToLeft,
          label: this.$t('components.custom.planning.booking-details-modal.general-information.table.photo'),
          sortable: false
        },
        {
          key: "about",
          label: this.$t('components.custom.planning.booking-details-modal.general-information.table.about'),
          sortable: true,
        },
        {
          key: "phoneNumber",
          class: this.alignCellsToCenter,
          label: this.$t("views.client.index.phoneNumber")
        },
        {
          key: "participantSubscription",
          class: this.alignCellsToCenter,
          label: this.$t("views.client.index.subscription")
        },
        {
          key: "colToPay",
          class: this.alignCellsToCenter,
          label: this.$t('components.custom.planning.booking-details-modal.general-information.table.price')
        },
        {
          key: "colPaid",
          class: this.alignCellsToCenter,
          label: this.$t('components.custom.planning.booking-details-modal.general-information.table.paid')
        },
      ];

      const confirmed = {
        key: "confirmedParticipation",
        class: this.alignCellsToCenter + ' confirmed-center',
        label: this.$t('components.custom.planning.booking-details-modal.general-information.table.confirmed-confirmation')
      };

      const actions = {
        key: "actions",
        class: this.alignCellsToCenter + ' w-action',
        label: this.$t('general.actions.actions'),
        sortable: false
      };

      if (this.booking.activityType !== FORMULA_BOOKING_TYPE) {
        bookingFields = insertIntoArray(bookingFields, bookingFields.length, actions);
      }

      const bookingOwner = {
        key: "bookingOwner",
        class: this.alignCellsToLeft,
        label: this.$t('components.custom.planning.booking-details-modal.general-information.table.booking-owner')
      };

      if (this.booking.activityType !== LESSON_BOOKING_TYPE) {
        bookingFields = insertIntoArray(bookingFields, bookingFields.length - 2, bookingOwner)
      }

      const lessonFields = insertIntoArray(bookingFields, 6, confirmed);

      const mobileFields = bookingFields.filter((v, i) => i !== 0 && i !== 2 && i !== 4);
      const mobileLessonFields = lessonFields.filter((v, i) => i !== 0 && i !== 2 && i !== 4);


      if (this.$store.getters['layout/getInnerWidth'] > 900) {
        return this.booking.activityType === LESSON_TYPE || this.booking.activityType === LEISURE_TYPE ? lessonFields : bookingFields;
      } else {
        return this.booking.activityType === LESSON_TYPE || this.booking.activityType === LEISURE_TYPE ? mobileLessonFields : mobileFields;
      }
    },
  },
  methods: {
    payParticipant(participant) {
      this.selectedParticipant = participant;
      this.display = !this.display;
    },
    showDetailsParticipant(participant) {
      this.selectedParticipant = participant;
      this.showDetails = true;
    },
    onRowClicked(scope) {
      let routeUrl = this.$router.resolve({name: 'customer_details', params: {id: scope.id}});
      window.open(routeUrl.href, '_blank');
    },
    removeParticipant(element) {
      const participantTodDelete = this.items.find(el => el.id === element.id);
      if (isNotUndefinedAndNotNull(participantTodDelete)) {
        this.items.splice(this.items.indexOf(participantTodDelete), 1);
        this.reloadParticipants();
      }
      if (isNotUndefinedAndNotNull(element.iriParticipant)) {
        const findCanceledParticipant = this.canceledParticipants.find(el => el === element.iriParticipant);

        if ("undefined" === typeof findCanceledParticipant) {
          this.canceledParticipants.push(element.iriParticipant)
        }
      }
      if (!this.booking.nameManuallyUpdated) {
        if (this.items.length === 0 && this.booking.activityType !== LESSON_TYPE) {
          this.booking.name = this.$store.getters["auth/currentClub"].name;
        }
      }
      _put(element.iriParticipant, {canceled: true})
        .finally(() => {
          this.$emit('on:participant-deleted');
        })
      ;
    },
    reloadParticipants() {
      setTimeout(() => {
        this.isBusy = false;
        this.updateBookingName();
        this.resizeTableRows();
      }, 500);
      this.$emit('on-participant-table-refreshed')
    },
    resizeTableRows() {
      this.totalRows = this.items.length;
    },
    updateBookingName() {
      if (this.booking.nameManuallyUpdated === false) {
        if (this.items.length === 0) {
          if (this.booking.activityType !== LESSON_BOOKING_TYPE) {
            this.booking.name = this.$store.getters["auth/currentClub"].name;
          }
        } else {
          if (this.booking.activityType !== LESSON_BOOKING_TYPE) {
            this.booking.name = '';

            for (const index in this.items) {
              if (this.items[index].participant) {
                if (this.booking.name === '') {
                  this.booking.name += this.getParticipantName(this.items[index].participant.item);
                } else {
                  this.booking.name += ' / ' + this.getParticipantName(this.items[index].participant.item);
                }
              }
            }
          }
        }
      }
    },
    getParticipantName(participantDetails) {
      return participantDetails.lastName ? capitalize(participantDetails.lastName) : capitalize(participantDetails.firstName);
    },
    avatar(client, promiseResponses) {
      if (client.identityPhoto) {
        return client.identityPhoto.contentUrl;
      } else {
        const findClient = promiseResponses.find(el => el.id === client.id);
        if (isNotUndefinedAndNotNull(findClient)) {
          if (findClient.user) {
            if (findClient.user.avatar) {
              return findClient.user.avatar.contentUrl;
            }
          }
        }
      }

      return null;
    },
    refreshBookingParticipants() {
      getBooking(this.booking.id)
        .then((response) => {
          hydrate(this.booking, response.data);
          this.refreshPayments = !this.refreshPayments;
          this.$emit('on:reset-items');
          this.$emit('on:payment-succeeded');
        })
      ;
    },
  },
}
</script>
<style scoped lang="scss">
/deep/ .page-item.active .page-link {
  z-index: 0;
  color: #fff;
  background-color: #092772;
  border-color: #092772;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: #0000001a;
}

/deep/ .datatable-vue table tbody td:last-child.w-action {
  padding: 0;
}

/deep/ .datatable-vue table tbody td.confirmed-center {
  display: flex;
  justify-content: center;
}
</style>
