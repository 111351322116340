<template>
  <b-modal
    :id="modalId"
    hide-header
    hide-footer
    hide-header-close
    size="lg"
    header-class="my-second-class"
    body-bg-variant="gray-lighten"
  >
    <div
      v-if="customer"
      :key="'modal-key' + contentModalKey"
      class="container-fluid"
    >
      <b-row class="pt-1 pb-1">
        <b-col align="left" class="popup-title">
          {{ $t('components.custom.planning.booking-details-modal.participant-payment-modal.title') }}
        </b-col>
      </b-row>
      <div class="border-bottom-grey-dark mt-3 mb-3"/>
      <b-row no-gutters>
        <b-col
          :cols="innerWidth ? '4' : '12'"
          align="center"
          class="payment-title d-flex align-items-center mb-1"
        >
          {{ $t('components.custom.planning.booking-details-modal.participant-payment-modal.payment-label') }}
        </b-col>
        <b-col
          class="btn-list"
          :align="innerWidth ? 'right' : ''"
          :class="innerWidth ? '' : 'd-flex justify-content-between mt-3'"
        >
          <d-button
            v-if="hasCredits"
            text="components.custom.planning.booking-details-modal.participant-payment-modal.btn.credit"
            class="d-btn-sm btn font-text-title mr-2"
            :class="[paymentMethod === 'payment_token' ? 'd-btn-primary-outline-new-selected' : 'd-btn-primary-outline-new',
             innerWidth ? '' : 'custom-customer-button']"
            @on:button-click="setPaymentMethod('payment_token')"
          />
          <d-button
            v-else
            text="components.custom.planning.booking-details-modal.participant-payment-modal.btn.credit"
            class="d-btn-sm btn font-text-title mr-2 disabled d-btn-primary-outline-new-disabled"
            :class="innerWidth ? '' : 'custom-customer-button'"
          />
          <d-button
            v-if="customer.wallet"
            text="components.custom.planning.booking-details-modal.participant-payment-modal.btn.wallet"
            class="d-btn-sm btn font-text-title mr-2"
            :class="[paymentMethod === 'wallet' ? 'd-btn-primary-outline-new-selected' : 'd-btn-primary-outline-new',
             innerWidth ? '' : 'custom-customer-button']"
            @on:button-click="setPaymentMethod('wallet')"
          />
          <d-button
            v-else
            text="components.custom.planning.booking-details-modal.participant-payment-modal.btn.wallet"
            class="d-btn-sm btn font-text-title mr-2 disabled d-btn-primary-outline-new-disabled"
            :class="innerWidth ? '' : 'custom-customer-button'"
          />
          <d-button
            text="components.custom.planning.booking-details-modal.participant-payment-modal.btn.pos"
            class="d-btn-sm btn custom-btn-padding font-text-title"
            :class="[paymentMethod === 'administration' ? 'd-btn-primary-outline-new-selected' : 'd-btn-primary-outline-new',
             innerWidth ? '' : 'custom-customer-button']"
            @on:button-click="setPaymentMethod('administration')"
          />
        </b-col>
      </b-row>
      <credit-form
        v-if="paymentMethod === 'payment_token' && hasCredits && isCreditLoaded"
        :participant="participant"
        :customer="customer"
        :to-pay="toPay"
        :selected-payment-token="selectedPaymentToken"
        :payment-token-prices="participantPaymentTokenPrices"
        :block-price-variations="blockPriceVariations"
      />
      <pos-form
        v-else-if="paymentMethod === 'administration'"
        :to-pay="toPay"
      />
      <wallet-form
        v-else
        :customer="customer"
        :to-pay="toPay"
      />
      <div class="border-bottom-grey-dark mt-3 mb-3"/>
      <b-row class="mt-3">
        <b-col
          :align="innerWidth ? 'right' : 'center'"
          :class="innerWidth ? '' : 'd-flex justify-content-between pl-0 pr-0'"
        >
          <d-button
            text="general.actions.cancel"
            class="d-btn-sm btn d-btn-default font-text-title mr-3"
            :class="innerWidth ? '' : 'custom-customer-button'"
            @on:button-click="$bvModal.hide(modalId)"
          />
          <d-button
            v-if="!isBusy"
            text="general.actions.save"
            class="d-btn-sm btn d-btn-primary-new font-text-title"
            :class="[toPay.value && parseFloat(toPay.value) > 0 ? '' : 'disabled',
             innerWidth ? '' : 'custom-customer-button']"
            @on:button-click="onSave"
          />
          <d-button
            v-else
            text="general.actions.save"
            class="d-btn-sm btn d-btn-primary-new font-text-title disabled"
            :class="innerWidth ? '' : 'custom-customer-button'"
            icon="fa fa-refresh fa-spin"
            icon-position="right"
          />
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>

import CreditForm from "@form/planning/participant-payment/CreditForm";
import PosForm from "@form/planning/participant-payment/PosForm";
import WalletForm from "@form/planning/participant-payment/WalletForm";
import {getClubClient} from "@api/doinsport/services/client/client.api";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import Item from "@/classes/doinsport/Item";
import Cart from "@/classes/doinsport/Cart";
import Payment from "@/classes/doinsport/Payment";
import {addCart, addPayment} from "@api/doinsport/services/payments/payments.api";
import {fromIriReferenceToId} from "@/utils/form";
import {getPricePaymentTokens, getPriceVariations} from "@api/doinsport/services/timetable/blocks/price/price.api";
import {toPrice} from "@/utils/currency";

const MODAL_ID = 'PARTICIPANT_PAYMENT_MODAL_ID';

export default {
  data: () => ({
    paymentMethod: 'administration',
    customer: null,
    isCreditLoaded: false,
    paymentTokens: [],
    blockPriceVariations: [],
    participantPaymentTokenPrices: [],
    selectedPaymentToken: {value: null},
    isBusy: false,
    toPay: {
      value: null
    },
    contentModalKey: 0,
  }),
  components: {
    WalletForm,
    PosForm,
    CreditForm
  },
  props: {
    modalId: {
      type: String,
      default: MODAL_ID,
    },
    timetableBlockPrice: {
      type: Object,
      default: this
    },
    display: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    participant: {
      type: Object,
      default: () => {
      },
    },
    booking: {
      type: Object,
      default: () => {
      },
    },
  },
  watch: {
    participant: function () {
      this.resetPaymentForm();
      this.loadCustomer();
    },
    display: function () {
      this.$bvModal.show(this.modalId);
    },
    hide: function () {
      this.$bvModal.hide(this.modalId);
    }
  },
  computed: {
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 900;
    },
    hasSubscriptionCardUsed() {
      if (isNotUndefinedAndNotNull(this.participant.variationUsed)) {
        if (this.participant.variationUsed.paymentTokenPrices.length > 0) {
          return true;
        }
      }

      return false;
    },
    hasCredits() {
      if (isNotUndefinedAndNotNull(this.customer.paymentTokens) && this.participantPaymentTokenPrices.length > 0) {
        if (this.customer.paymentTokens.length > 0) {
          return this.customer.paymentTokens.length > 0;
        }
      }

      return false;
    }
  },
  methods: {
    onSave() {
      this.addPayment();
    },
    resetPaymentForm() {
      this.contentModalKey++;
      this.paymentMethod = 'administration';
      this.customer = null;
      this.selectedPaymentToken.value = null;
      this.toPay.value = null;
    },
    loadCustomer() {
      getClubClient(this.participant.id)
        .then((response) => {
          this.customer = response.data;
        })
        .finally(() => {
          this.loadBlockPriceVariations();
        })
      ;
    },
    initSelectedPaymentToken() {
      if (this.customer.paymentTokens.length > 0) {
        for (const paymentTokenPrice of this.participantPaymentTokenPrices) {
          const checkIfValid = this.customer.paymentTokens.find(el => '/clubs/payment-tokens/' + el.clubPaymentTokenId === paymentTokenPrice.paymentToken);

          if (isNotUndefinedAndNotNull(checkIfValid)) {
            this.selectedPaymentToken.value = checkIfValid.clubPaymentTokenId;
          }
        }
      }
    },
    addPayment() {
      if (!this.isBusy && (this.toPay.value && parseFloat(this.toPay.value) > 0)) {
        this.isBusy = true;
        const amountToPay = this.getPriceValue();

        const item = new Item({
          quantity: 1,
          product: this.booking['@id'],
          price: amountToPay,
        });
        const cart = new Cart({
          items: [item]
        });
        const payment = new Payment({
          client: this.customer['@id'],
          amount: parseInt((amountToPay * 100) + '', null),
          provider: this.paymentMethod,
          method: 'card',
          club: '/clubs/' + this.$store.getters["auth/currentClub"].id,
          metadata: {
            addedBy: this.$store.getters["auth/currentUser"] ? this.$store.getters["auth/currentUser"].firstName : 'admin',
            paymentTokenId: this.paymentMethod === 'payment_token' ? this.getPaymentTokenId() : null,
          },
          currency: this.$store.getters["auth/currentClub"].currency,
          cart: cart,
        });

        addCart(cart).then(resp => {
          if (resp.data) {
            payment.cart = resp.data['@id'];
            addPayment(payment)
              .then(resp => {
                if (resp.data) {
                  if (resp.data.status === "succeeded") {
                    this.$emit('on:payment-succeed');
                  } else {
                    this.$flash(null, this.$t('delete_booking.failed_content'));
                  }
                }
              })
              .finally(() => {
                this.isBusy = false;
                this.$bvModal.hide(this.modalId);
              })
            ;
          }
        });
      }
    },
    getPaymentTokenId() {
      return this.selectedPaymentToken.value;
    },
    getPriceValue() {
      if (this.paymentMethod !== 'payment_token') {
        return this.toPay.value;
      } else {
        if (this.customer.paymentTokens.length && null !== this.participant.subscriptionCardUsed) {
          for (const paymentTokenPrice of this.participantPaymentTokenPrices) {
            if (
              null !== paymentTokenPrice.blockPriceVariation
              &&
              paymentTokenPrice.paymentToken === '/clubs/payment-tokens/' + this.selectedPaymentToken.value
            ) {
              if (this.participant.subscriptionCardUsed.plan['@id'] === paymentTokenPrice.blockPriceVariation.subscriptionPlan) {
                return (toPrice(this.participant.colToPay) / paymentTokenPrice.pricePerParticipant) * this.toPay.value;
              }
            }
          }

        } else {
          return this.payCreditWithPublicPrice(this.timetableBlockPrice);
        }
      }
    },
    payCreditWithPublicPrice(blockPrice) {
      if (this.customer.paymentTokens.length) {
        for (const paymentTokenPrice of this.participantPaymentTokenPrices) {
          if (
            null === paymentTokenPrice.blockPriceVariation
            &&
            paymentTokenPrice.paymentToken === '/clubs/payment-tokens/' + this.selectedPaymentToken.value
          ) {
            let defaultPrice = (blockPrice.pricePerParticipant / 100);

            if (isNotUndefinedAndNotNull(this.participant.category)) {
              defaultPrice = parseFloat(toPrice(this.participant.colToPay));
            }

            return (defaultPrice / paymentTokenPrice.pricePerParticipant) * this.toPay.value;
          }
        }
      }
    },
    loadBlockPriceVariations() {
      const blockPriceId = fromIriReferenceToId('/clubs/playgrounds/timetables/blocks/prices/', this.booking.timetableBlockPrice['@id']);
      const blockPrice = this.timetableBlockPrice;

      this.blockPriceVariations = [];

      getPriceVariations(blockPriceId, 500)
        .then((response) => {
          for (const priceVariation of response.data['hydra:member']) {
            this.blockPriceVariations.push({
              iri: priceVariation['@id'],
              publicPrice: blockPrice.pricePerParticipant,
              pricePerParticipant: priceVariation.pricePerParticipant,
              paymentTokenPrices: priceVariation.paymentTokenPrices,
              subscriptionPlan: priceVariation.subscriptionPlan
            });
          }
        })
        .finally(() => {
          this.loadPaymentTokenPrices(blockPriceId);
        })
      ;
    },
    isValidPaymentToken(paymentTokenIri) {
      const paymentTokenId = fromIriReferenceToId('/clubs/payment-tokens/', paymentTokenIri);
      const findPaymentToken = this.customer.paymentTokens.find(el => el.clubPaymentTokenId === paymentTokenId);

      return isNotUndefinedAndNotNull(findPaymentToken);
    },
    loadPaymentTokenPrices(blockPriceId) {
      this.participantPaymentTokenPrices = [];

      getPricePaymentTokens(blockPriceId)
        .then((response) => {
          for (const paymentTokenPrice of response.data['hydra:member']) {
            if (this.isValidPaymentToken(paymentTokenPrice.paymentToken)) {
              this.participantPaymentTokenPrices.push(paymentTokenPrice);
            }
          }
        })
        .finally(() => {

          this.initSelectedPaymentToken();
          this.isCreditLoaded = true;
        })
      ;
    },
    setPaymentMethod(method) {
      switch (method) {
        case 'wallet':
          if (this.customer.wallet) {
            this.paymentMethod = 'wallet'
          }
          break;
        case 'payment_token':
          if (this.hasCredits) {
            this.paymentMethod = 'payment_token'
          }
          break;
        default:
          this.paymentMethod = method;
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/_modal.scss";
@import "@lazy/bookings/participant-payment-modal/_participant-payment-modal.scss";
@import "@lazy/_custom-buttom-mobile.scss";

@media (min-width: 992px) {
  /deep/ .modal-lg, .modal-xl {
    max-width: 600px;
  }
}
</style>
